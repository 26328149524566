import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Button, Icon, IconButton, MenuItem, TextField} from "@material-ui/core";

function AdvancedParams(props) {
    const {values, onConfigChange, basicSetting} = props;
    const [params, setParams] = useState([]);

    useEffect(() => {
        setParams(values)
    }, [])

    const removeParam = (index) => {
        values.splice(index, 1);
        setParams(values);
        setParams([...values], onConfigChange(values));
    }
    const addNewParam = () => {
        values.push({
            key: '',
            value: '',
        });
        setParams([...values], onConfigChange(values));
    }

    const onChange = (evt, index, type) => {
        if (type === 0) {
            values[index].key = evt.target.value.trim();
        } else {
            values[index].value = evt.target.value;
        }
        setParams([...values], onConfigChange(values));
    }

    return (
        <div className="output" style={{width: "100%"}}>
            <div className="actions">
                {
                    basicSetting.inputType === 'aCANFeeder' || basicSetting.inputType === 'SRT' ?
                        <Button onClick={addNewParam}>Add New Parameter</Button>
                        : ''
                }
            </div>
            {
                params.map((element, index) => (
                    <React.Fragment key={index}>
                        <div className="lists">
                            <span>Name {index + 1}:</span>
                            <TextField className="protocal" value={element.key || ''} onChange={evt => {
                                onChange(evt, index, 0)
                            }}/>
                            <span>Value {index + 1}:</span>
                            <TextField className="ip" value={element.value || ''} onChange={evt => {
                                onChange(evt, index, 1)
                            }}/>
                            <IconButton onClick={() => removeParam(index)}>
                                <Icon>delete</Icon>
                            </IconButton>
                        </div>
                    </React.Fragment>
                ))
            }
        </div>
    )
}

AdvancedParams.propTypes = {
    values: PropTypes.array.isRequired,
    basicSetting: PropTypes.object.isRequired,
    onConfigChange: PropTypes.func.isRequired
}

export default AdvancedParams