import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from "@material-ui/core/DialogContent"
import { DialogTitle, DialogActions } from '@material-ui/core'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import {FormHelperText} from "@material-ui/core"
import { loginUser } from '../../utils'
import {setLoading, showAlert} from "../../action";
import {connect} from "react-redux";
import {Contribution} from "../../pages/Contribution";

export const ServerSelectingDialog = (props) => {
    const { onClose, open, servers, groups } = props

    const Schema = Yup.object().shape({
        server: Yup.string()
            .test("validation", "Select a server", (value) => value !== 1)
            .required('This field is required'),
        group: Yup.string()
            .test("validation", "Select a group", (value) => value !== 1)
            .required('This field is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            server: "1",
            group: loginUser() === "manager" && groups.length ? groups[0].id : "1"
        },
        validationSchema: Schema,
        onSubmit: (values, {resetForm}) => {
            handleClose(values)
            // resetForm()
        },
    });

    const handleClose = (val = false) => {
        onClose(val)
    };

    const selectOptions = (items) => {
        return items.map((item, index) => {
          return (
              <MenuItem value={item.id} key={index}>{item.title}</MenuItem>
          )
        })
    }

    const {errors, touched, handleSubmit, getFieldProps, values} = formik;
    
    return (
        <Dialog disableBackdropClick disableEscapeKeyDown={true} onClose={handleClose} open={open}>
            <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <DialogTitle>Select a server</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    className='width-100'
                                    error={Boolean(touched.server && errors.server)}
                                 >
                                    <InputLabel id="label">
                                        Select Server
                                    </InputLabel>
                                    <Select
                                        labelId="label"
                                        label="Select Server"
                                        {...getFieldProps('server')}
                                    >
                                        <MenuItem value={1} disabled={true}>
                                            === Select ===
                                        </MenuItem>
                                        {servers.length && selectOptions(servers)}
                                    </Select>
                                    <FormHelperText>{touched.server && errors.server}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    className='width-100'
                                    error={Boolean(touched.group && errors.group)}
                                 >
                                    <InputLabel id="label">
                                        Select Group
                                    </InputLabel>
                                    <Select
                                        labelId="label"
                                        label="Select group"
                                        {...getFieldProps('group')}
                                    >
                                        <MenuItem value={1} disabled={true}>
                                            === Select ===
                                        </MenuItem>
                                        {groups.length && selectOptions(groups)}
                                    </Select>
                                    <FormHelperText>{touched.group && errors.group}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className='mx-1'>
                        <Button type='submit' disabled={values.server === "1" || values.group === "1"} variant="contained" color="primary">
                            ok
                        </Button>
                        <Button type='reset' variant="contained" color="secondary" className='ml-1' onClick={() => {formik.resetForm(); handleClose()}}>
                            close
                        </Button>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}

ServerSelectingDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    servers: PropTypes.array.isRequired,
    groups: PropTypes.array.isRequired,
}
const mapStateToProps = (state) => ({})

const mapDispatchToProps = {setLoading}

export default connect(mapStateToProps, mapDispatchToProps)(ServerSelectingDialog)

// export default (ServerSelectingDialog)
