import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import GroupDialog from '../../components/Group/GroupDialog'
import {
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    Button,
    IconButton,
    Icon
} from '@material-ui/core'
import {paramPost, noParamGet, paramPut, noParamDelete} from '../../apis/CommonApi'
import {ToastsStore} from 'react-toasts'
import {showAlert, setLoading} from '../../action'
import './style.scss'

const Group = (props) => {
    const [open, setOpen] = useState(false)
    const [managers, setManagers] = useState([])
    const [groups, setGroups] = useState([])
    const [editGroupId, setEditGroupId] = useState(undefined);
    const [param, setParam] = useState({
        title: "",
        description: "",
        manager: "1"
    })

    useEffect(() => {
        getUsers()
        getGroups()
    }, [])

    const refresh = async () => {
        getUsers();
        getGroups();

    }

    const getUsers = () => {
        props.setLoading(true);
        noParamGet("/api/users/getUsers")
            .then(res => {
                console.log("User gettting result ==", res)
                setManagers(res.data)
                props.setLoading(false)
            })
            .catch(err => {
                props.setLoading(false)
                console.log("User getting Error ==", err)
            })
    }

    const getGroups = async () => {
        await noParamGet("/api/group")
            .then(res => {
                console.log("Getting group result ==", res)
                setGroups(res.data)
            })
            .catch(err => {
                console.log("Getting group Error ==", err)
            })
    }

    const handleOpen = () => {
        setParam({
            title: "",
            description: "",
            manager: "1"
        })
        setOpen(!open)
        setEditGroupId(undefined)
    }

    const handleClose = async (val) => {
        if (val) {
            console.log(val, editGroupId)
            if (!editGroupId) {
                await paramPost("/api/group", val)
                    .then(async res => {
                        console.log("Group added successfully==", res)
                        ToastsStore.success('Group added successfully');
                        await getGroups()
                        setOpen(!open)
                    })
                    .catch(err => {
                        console.log("Group Creating Error ==", err)
                        ToastsStore.error(err.data.error || 'Failed to create group');
                    })
            } else {
                await paramPut(`/api/group/${editGroupId}`, val)
                    .then(async res => {
                        console.log("Group updated successfully==", res)
                        ToastsStore.success('Group updated successfully');
                        await getGroups()
                        setOpen(!open)
                    })
                    .catch(err => {
                        console.log("Group updating Error ==", err)
                        ToastsStore.error(err.data.error || 'Failed to update group');
                    })
            }
        } else {
            console.log("Closing without any value")
            setOpen(!open)
            clearState()
        }
    }

    const clearState = () => {
        setParam({
            title: "",
            description: "",
            manager: "1"
        })
    }

    const editGroup = (group) => {
        setEditGroupId(group._id)
        setParam({
            title: group.title,
            description: group.description,
            manager: group.userInfo.length ? group.userInfo[0]._id : "1"
        })
        setOpen(true);
    }

    const handleRemoveGroup = async (id) => {
        await noParamDelete(`/api/group/${id}`)
            .then(async res => {
                console.log("Group deleted successfully==", res)
                ToastsStore.success('Group deleted successfully')
            })
            .catch(err => {
                console.log("Group delete Error ==", err)
                ToastsStore.error(err.data.error || 'Failed to delete group');
            })
    }

    const removeGroup = (groupId) => {
        console.log("group id", groupId);
        props.showAlert('Are you sure?', 'Are you sure you want to delete this Group?', async () => {
            await handleRemoveGroup(groupId);
            await getGroups();
        });
    }

    const groupList = () => {
        if (groups.length) {
            return (
                groups.map(group => (
                    <TableRow key={group._id}>
                        <TableCell>{group.title}</TableCell>
                        <TableCell>{group.description}</TableCell>
                        <TableCell>{group.userInfo.length ? group.userInfo[0].mode.name === "manager" ? group.userInfo[0].fullName : "" : ""}</TableCell>
                        <TableCell>
                            <IconButton onClick={() => editGroup(group)} color="secondary">
                                <Icon>edit</Icon>
                            </IconButton>
                            <IconButton onClick={() => removeGroup(group._id)} className="color-danger">
                                <Icon>delete</Icon>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))
            )
        }

    }

    return (
        <div className="group">
            <div className="sub-page-header">
                {'Group Manager'}
                <div className="add-button">
                    <Button variant="text" className={""} onClick={() => refresh()}>Refresh</Button> &nbsp;&nbsp;&nbsp;
                    <Button variant="contained" color="primary" onClick={handleOpen}>Add New
                        Group</Button>
                </div>
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Group Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Group Manager</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groupList()}
                    </TableBody>
                </Table>
            </TableContainer>
            <GroupDialog open={open} onClose={handleClose} params={param} users={managers}/>
        </div>
    )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, {showAlert, setLoading})(Group);