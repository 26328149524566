import React, {useState, useEffect} from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from "@material-ui/core/DialogContent"
import {DialogTitle, DialogActions, TextField} from '@material-ui/core'
import {Button} from '@material-ui/core'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import {FormHelperText} from "@material-ui/core"
import config from "../../config";
import Encryption from "../../pages/Contribution/settingTypes/Encryption";
import {paramPost, paramPut} from "../../apis/CommonApi";
import {ToastsStore} from "react-toasts";

export const DestinationDialog = (props) => {
    const {onClose, open, addingModal, groups, getDestinations, params, destination} = props;
    const [groupSelected, setGroupSelected] = useState('');
    const [serverIp, setServerIp] = useState('');
    const [title, setTitle] = useState('');
    const [ip, setIp] = useState(''); // Destination IP
    const [hasError, setHasError] = useState({
        selectServer: false,
        selectGroup: false,
        serverIp: false,
        title: false,
        ip: false,
    });
    const [encryption, setEncryption] = useState({
        selection: 'no',
        passphrase: '',
        type: '128'
    });

    useEffect(() => {
        if (loginUser() === 'manager') {
            setGroupSelected(groups[0].id);
        }
        if (open && addingModal === 'edit') {
            initialState();
        }
    }, [open]);

    const initialState = () => {
        setGroupSelected(destination.groupId);
        setTitle(destination.title);
        setIp(destination.ip);
        setEncryption(destination.encryption || {
            selection: 'no',
            passphrase: '',
            type: '128'
        });
    }


    const emptyAllState = () => {
        setGroupSelected('');
        setServerIp('');
        setTitle('');
        setIp('');
        setEncryption({
            selection: 'no',
            passphrase: '',
            type: '128'
        });
    }

    const loginUser = () => {
        let user = localStorage.getItem(config.userLocalstorage)
        if (user) {
            user = JSON.parse(user)
            return user.mode.name
        }
        return null
    }

    const onSubmit = async evt => {
        evt.preventDefault();
        const data = {
            title,
            ip,
            groupId: groupSelected,
            encryption
        };
        props.setLoading(true);
        if (addingModal === 'create') {
            await paramPost("/api/contribution/destination", data)
                .then(async res => {
                    console.log(res);
                    if (res.data.status) {
                        ToastsStore.success('Destination added successfully');
                        closeModal();
                        await getDestinations(params);
                    } else {
                        ToastsStore.error(res.data.msg);
                    }
                    props.setLoading(false);
                })
                .catch(err => {
                    props.setLoading(false);
                    ToastsStore.error('Failed to create destination');
                });
        } else if (addingModal === 'edit') {
            await paramPut(`/api/contribution/destination/${destination.id}`, data)
                .then(async res => {
                    console.log(res);
                    if (res.data.status) {
                        ToastsStore.success(res.data.msg);
                        closeModal();
                        await getDestinations(params);
                    } else {
                        ToastsStore.error(res.data.msg);
                    }
                    props.setLoading(false);
                })
                .catch(err => {
                    props.setLoading(false);
                    ToastsStore.error('Failed to create destination');
                });
        }
        props.setLoading(false);
    }

    const closeModal = () => {
        emptyAllState();
        onClose();
    }

    const selectOptions = (items) => {
        return items.map((item, index) => {
            return (
                <MenuItem value={item.id} key={index}>{item.title}</MenuItem>
            )
        })
    }

    const changeValue = field => evt => {
        const value = evt.target.value;
        switch (field) {
            case 'group':
                const group = groups.find(el => el.id === value);
                if (group) {
                    setGroupSelected(group.id);
                } else {
                    setGroupSelected('');
                }
                break;
            case 'title':
                setTitle(value);
                break;
            case 'ip':
                setIp(value);
                break;
        }

    };

    const handleEncryptionChange = (type, value) => {
        setEncryption(value);
    }

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown={true} onClose={closeModal}
                open={open} fullWidth maxWidth="lg">
            <form autoComplete="off" onSubmit={onSubmit}>
                <DialogTitle>{addingModal === 'create' ? 'Add new destination' : 'Edit destination'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {loginUser() !== 'manager' ? (
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    className='width-100'
                                >
                                    <InputLabel id="label-group">
                                        Select Group
                                    </InputLabel>
                                    <Select
                                        labelId="label"
                                        label="Select Group"
                                        onChange={changeValue('group')}
                                        error={hasError.selectGroup}
                                        value={groupSelected}
                                    >
                                        <MenuItem value={1} disabled={true}>
                                            === Select ===
                                        </MenuItem>
                                        {groups.length && selectOptions(groups)}
                                    </Select>
                                    {/*<FormHelperText>Select server</FormHelperText>*/}
                                </FormControl>
                            </Grid>
                        ) : ''}
                        <Grid item xs={6}>
                            <TextField
                                label="Title"
                                type={"text"}
                                variant="outlined"
                                error={hasError.title}
                                color={"primary"}
                                value={title}
                                onChange={changeValue('title')}
                                InputProps={{
                                    required: true,
                                }}
                            />
                            {hasError.title && <FormHelperText>Title is required</FormHelperText>}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Destination IP"
                                type={"text"}
                                variant="outlined"
                                error={hasError.ip}
                                color={"primary"}
                                value={ip}
                                onChange={changeValue('ip')}
                                InputProps={{
                                    required: true,
                                }}
                            />
                            {hasError.ip && <FormHelperText>{hasError.ip}</FormHelperText>}
                        </Grid>
                        <Grid item xs={12}>
                            <Encryption values={encryption}
                                        onConfigChange={(val) => handleEncryptionChange(3, val)}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className='mx-1'>
                    <Button type='submit'
                            variant="contained" color="primary">
                        ok
                    </Button>
                    <Button type='reset' variant="contained" color="secondary" className='ml-1' onClick={closeModal}>
                        close
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

DestinationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    addingModal: PropTypes.string.isRequired,
    groups: PropTypes.array.isRequired,
    getDestinations: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    destination: PropTypes.object,
}

export default (DestinationDialog)
