import {
  encoderActions
} from '../../../action/types';

//initialize dashboard state-
const INIT_STATE = {
  open: false,
  encoderId: '',
  encoder: {},
  editConfigIndex: -1
};
//---------------------------

/**********************
 * Auth user reducers *
 **********************/
export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case encoderActions.SHOW_DIALOG:
      const { open, encoderId, encoderToEdit } = action.payload;
      return { open, encoderId, encoderToEdit };

    case encoderActions.SET_ENCODER:
      const { encoder, editConfigIndex } = action.payload;
      return { encoder, editConfigIndex };

    default: return { ...state };
  }
}
