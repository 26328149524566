import React, {useEffect, useState} from 'react'
import {TextField, MenuItem} from '@material-ui/core'
import PropTypes from 'prop-types'

function FEEDER(props) {

  const {values, onConfigChange, keys, onModeChange} = props
  const [params, setParams] = useState({})
  
  useEffect(() => {
    setParams(values)
  }, [values])

  const changeValue = (e, field) => {
    let param = params;
    const target = e.target.value
    param[field] = target;
    setParams({...params}, onConfigChange(param));
    if(field === 'mode' && ['Listen', 'Push'].includes(target)) {
      onModeChange(target);
    }
  }

  const renderItem = ({ label, field, type = 'text', children = [] }) => (
    <div className="lists" key={field}>
      {field === 'host' ? (
        <div className="title">Local IP</div>
      ) : (
        <div className="title">{label}</div>
      )}
      <div className="content">
        {
          type === 'select'
            ? <TextField
              fullWidth select
              value={params[field] || ''}
              onChange={(e) => changeValue(e, field)} >
              {
                children.map((child, index) => <MenuItem value={child.value || child} key={child.value || child}>{child.label || child}</MenuItem>)
              }
            </TextField>
            : <TextField
              fullWidth
              InputProps={{
                // readOnly: field === 'multicast',
              }}
              type={type}
              value={params[field] || ''}
              onChange={(e) => changeValue(e, field)} />
        }
      </div>
    </div>
  );

  return (
    <div className="panda-video-stream" style={{width: 'inherit'}}>
      {
        keys.map(element => renderItem({ ...element }))
      }
    </div>
  )
}

FEEDER.propTypes = {
  values: PropTypes.object.isRequired,
  onConfigChange: PropTypes.func.isRequired,
  onModeChange: PropTypes.func.isRequired,
  keys: PropTypes.array.isRequired
}

export default FEEDER