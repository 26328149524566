import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { Route } from 'react-router-dom';
import { setLoading, jwtLogin } from '../../action'
import { Header, EncoderDialog } from '../../components';
import { UserManager, EditEncoder, EncoderList, ManagerEncoder } from '../../pages';
import Group from '../../pages/Group';
import Servers from '../../pages/Servers';
import Contribution from '../../pages/Contribution';
import ContributionConfig from '../../pages/Contribution/settingConfig';
import OutputConfig from '../../pages/Contribution/outputConfig';

const dashboardUrl = '/dashboard';
class Dashboard extends Component {
  _isMounted = false;

  state = {
    router: '',
    loading: true
  }

  componentDidMount() {
    this._isMounted = true;
    this.propsToState();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.propsToState(nextProps);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  propsToState = async (props = this.props) => {
    const { authUser, history, location } = props;
    if (this._isMounted) {
      if (location.pathname !== this.state.router) {
        this.setState({ router: location.pathname }, async () => await this.props.jwtLogin());
      }
      if (authUser.loading === true && !authUser.user) {
        this.setState({ loading: true })
      } else if (authUser.user) {
        this.setState({ loading: false })
      } else {
        history.push('/login');
      }
    }
  }
  render() {
    return (
      !this.state.loading ?
        <div className="dashboard-container">
          <Header />
          <div className="container">
            <Route path={`/`} exact component={EncoderList} />
            {/* <Route path={`${dashboardUrl}/`} exact component={EncoderList} /> */}
            <Route path={`${dashboardUrl}/userManager`} component={UserManager} />
            <Route path={`${dashboardUrl}/editEncoder/:id`} component={EditEncoder} />
            <Route path={`${dashboardUrl}/managerEncoder`} component={ManagerEncoder} />
            <Route path={`${dashboardUrl}/group`} component={Group} />
            <Route path={`${dashboardUrl}/server`} component={Servers} />
            <Route path={`${dashboardUrl}/contribution`} exact component={Contribution} />
            <Route path={`${dashboardUrl}/:serverId/:groupId/contribution/:id`} component={ContributionConfig} />
            <Route path={`${dashboardUrl}/:serverId/output/:id`} component={OutputConfig} />
            <Route path={`${dashboardUrl}/:serverId/output_edit/:outputId`} component={OutputConfig} />
          </div>
          <EncoderDialog />
        </div>
        : ''
    )
  }
}

const mapStateToProps = ({ authUser }) => ({
  authUser
});
export default withRouter(connect(mapStateToProps, {
  setLoading, jwtLogin
})(Dashboard));