import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ToastsStore } from 'react-toasts';
import { TableContainer, TableHead, TableRow, TableCell, TableBody, Table, IconButton, Icon, Button, Tooltip } from '@material-ui/core'
import {getUsers, updateUser, addUserApi, removeUser, addUserToGroup, updateUserGroup} from '../../apis/Authontication';
import UserManagerDialog from '../../components/UserManagerDialog';
import GroupSelectorDialog from '../../components/AddUserDialog/GroupSelector';
import { showAlert, setLoading } from '../../action'
import { noParamGet } from '../../apis/CommonApi';


import './style.scss'
import config from "../../config";

const userMode = ['monitor', 'editor']
class UserManager extends Component {
  _isMounted = false;

  state = {
    openEditDialog: false,
    editId: '',
    users: [],
    groups: [],
    addGroupDialog: false
  }

  async componentDidMount() {
    this._isMounted = true;
    this.props.setLoading(true);
    await this.propsToState();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = async (props = this.props) => {
    try {
      if (!this._isMounted) return;
      this._isMounted = false;
      const users = await getUsers();
      const groups = await noParamGet("/api/group");
      this.setState({ users, groups: groups.data });
      this.props.setLoading(false)
    } catch (error) {
      this.props.setLoading(false)
      console.log(error);
    }
  }

  editUser = editId => {
    this.setState({ editId, openEditDialog: true });
  }
  onCloseDialog = () => this.setState({ openEditDialog: false });

  openAddGroupDialog = userId => {
    this.setState({ editId: userId, addGroupDialog: true })
  }

  handleCloseGroupDialog = async val => {
    if(!val) {
      return ToastsStore.warning("Please select a group")
    }
    if(val === 'exit') {
      return this.setState({ addGroupDialog: false })
    }
    const res = await addUserToGroup({groupId: val, userId: this.state.editId})
    if(res) {
      ToastsStore.success("User added to group successfully")
      this.setState({ addGroupDialog: false })
    }
    console.log("=== Result ===", res)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  addNewUser = () => {
    this.setState({ editId: '', openEditDialog: true });
  }

  onSave = async (userInfo, groupSelected = null) => {
    if(userInfo.newPassword && userInfo.newPassword.length > 0) {
      console.log(userInfo.newPassword,userInfo.newConfirmPassword)
      if(userInfo.newPassword !== userInfo.newConfirmPassword) {
        return ToastsStore.warning("New password and confirm password not match!");
      }
      delete userInfo.newConfirmPassword;
    }
    else {
      delete userInfo.newPassword;
      delete userInfo.newConfirmPassword;
    }
    console.log("user inf0======", userInfo)
    console.log("user Group selected if manager======", groupSelected)
    // return;
    if (userInfo._id) {
      let result = await updateUser(userInfo);
      result && ToastsStore.success('Success update user information');
      if(result && groupSelected) {
        const rs = await updateUserGroup({groupId: groupSelected, userId: result.id});
      }
    } else {
      let result = await addUserApi(userInfo);
      result && ToastsStore.success('Success add user ')
      if(result && groupSelected) {
        await addUserToGroup({groupId: groupSelected, userId: result.id});
      }
    }
    const users = await getUsers();
    this.setState({ users, openEditDialog: false })
    return true;
  }

  removeUser = async id => {
    this.props.showAlert('Are you sure?', 'Are you sure you want to delete this User?', async () => {
      await removeUser(id);
      ToastsStore.success('Success remove user ')
      const users = await getUsers();
      this.setState({ users, openEditDialog: false })
    });
  }

  loginUser = () => {
    let user = localStorage.getItem(config.userLocalstorage)
    if(user) {
      user = JSON.parse(user)
      return user.mode.name
    }
    return null
  }

  render() {
    const { users, editId, openEditDialog, addGroupDialog, groups } = this.state;
    return (
      <div className="user-manager">
        <div className="sub-page-header">
          {'Access Management'}
          <div className="add-button">
            <Button variant="text" className={""} onClick={() => this.componentDidMount()}>Refresh</Button> &nbsp;&nbsp;&nbsp;
            {!userMode.includes(this.loginUser()) && <Button variant="contained" color="primary" onClick={this.addNewUser}>Add user</Button>}
          </div>
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                users.map(user => (
                  <TableRow key={user._id}>
                    <TableCell>{user.fullName || ''}</TableCell>
                    <TableCell>{user.email || ''}</TableCell>
                    <TableCell>{user.modeInfo && user.modeInfo.name}</TableCell>
                    <TableCell>
                      <Tooltip title="Edit User">
                        <IconButton onClick={() => this.editUser(user._id)} color="secondary">
                          <Icon>edit</Icon>
                        </IconButton>
                      </Tooltip>
                      {user.modeInfo && userMode.includes(user.modeInfo.name) && !userMode.includes(this.loginUser()) ? (
                        <Tooltip title="Add To Group">
                        <IconButton onClick={() => this.openAddGroupDialog(user._id)} className="color-danger">
                          <Icon>group_add</Icon>
                        </IconButton>
                      </Tooltip>
                      ) : (
                        <IconButton className="color-disabled" style={{cursor: "default"}}>
                          <Icon>group_add</Icon>
                        </IconButton>
                      )}
                      {
                        !userMode.includes(this.loginUser()) &&
                          <Tooltip title="Delete User">
                            <IconButton onClick={() => this.removeUser(user._id)} className="color-danger">
                              <Icon>delete</Icon>
                            </IconButton>
                          </Tooltip>
                      }
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <UserManagerDialog
          open={openEditDialog}
          onClose={this.onCloseDialog}
          onSave={this.onSave}
          userId={editId}
        />
        <GroupSelectorDialog
          open={addGroupDialog}
          groups={groups}
          onClose={this.handleCloseGroupDialog}
         />
      </div>
    )
  }
}

const mapStateToProps = () => ({
});
export default connect(mapStateToProps, {
  showAlert, setLoading
})(UserManager);