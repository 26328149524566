import { encoderActions } from "../types";

export const editEncoder = (encoderId, encoder = {}) => dispatch => dispatch({
  type: encoderActions.SHOW_DIALOG,
  payload: {
    open: true, encoderId, encoderToEdit: encoder
  }
});

export const closeEncoder = () => dispatch => dispatch({
  type: encoderActions.SHOW_DIALOG,
  payload: { open: false }
});

export const setEncoder = (encoder, editConfigIndex) => dispatch => {
  dispatch({
    type: encoderActions.SET_ENCODER,
    payload:{encoder, editConfigIndex}
  })
}