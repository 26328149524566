import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import EnhancedTableHead from './EnhancedTableHead';
import { Icon, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ReplayIcon from '@material-ui/icons/Replay';
import PropTypes from 'prop-types';


const headerList = ['Status', 'Name', 'Destination', 'Uptime', 'Bitrate', 'Actions']

const useStyles = makeStyles((theme) => ({
  root: {
    width: '95%',
    padding: '1rem 0',
    marginLeft: '70px'
  },
  gridContainer: {
    padding: '2rem'
  },
  title: {
    textAlign: 'center',
    fontSize: '1.2rem',
    color: '#5a97fa',
    fontWeight: '600'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export const SubTable = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const {rows, onOutputEdit, onOutputActionChange, onOutputDelete} = props;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;


  return (
          <div className={classes.root}>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  headerList={headerList}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rows.length}
                  isSubHeader={true}
                />
                <TableBody>
                  {rows.length ? rows.map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <React.Fragment key={row.id}>
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}                            
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onClick={(event) => handleClick(event, row.name)}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>
                            <TableCell id={labelId} scope="row" padding="none" className={row.apiResult && row.apiResult.status ? 'color-green' : 'color-danger'}>
                              {row.apiResult ? row.apiResult.status ? "Online" : "Offline" : ""}
                            </TableCell>
                            <TableCell align="left">{row.title}</TableCell>
                            <TableCell align="left">{row.fat}</TableCell>
                            <TableCell align="left">{row.carbs}</TableCell>
                            <TableCell align="left">{row.protein}</TableCell>
                            <TableCell align="left">
                            {row.apiResult ? row.apiResult.status ? (
                              <Tooltip title="Stop" className='color-danger'>
                                <IconButton onClick={() => onOutputActionChange({id: row.id, type: 2, actionType: "stop"})}>
                                  <StopIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <>
                              <Tooltip title="Start">
                                <IconButton color="secondary" onClick={() => onOutputActionChange({id: row.id, type: 2, actionType: "start"})}>
                                  <PlayArrowIcon />
                                </IconButton>
                              </Tooltip>                              
                              <Tooltip title="Restart">
                                <IconButton color="secondary" onClick={() => onOutputActionChange({id: row.id, type: 2, actionType: "restart"})}>
                                  <ReplayIcon />
                                </IconButton>
                              </Tooltip>
                              </>
                            ) : ""}                              
                              <Tooltip title="Edit">
                                <IconButton color="primary"  onClick={() => onOutputEdit(row)}>
                                  <Icon>edit</Icon>
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton className='color-danger' onClick={() => onOutputDelete({id: row.id, type: 2})}>
                                  <DeleteForeverIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    }) : (
                      <TableRow style={{ height: 53}}>
                        <TableCell colSpan={8} />
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
    </div>
  );
}

SubTable.propTypes = {
  rows: PropTypes.array.isRequired,
  onOutputEdit: PropTypes.func.isRequired,
  onOutputActionChange: PropTypes.func.isRequired,
  onOutputDelete: PropTypes.func.isRequired,
}

export default SubTable
