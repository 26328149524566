import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

import { setLoading, showAlert } from '../../action'
import { controlApi, getEncoderStatus, configDeleteApi } from '../../apis/Encoder'
import './style.scss'
import { ToastsStore } from 'react-toasts';
import TailDialog from './TailDialog';
import axios from "axios";

/**
 * Encoder Card
 * @augments {Component<Props, State>}
 */
class EncoderCard extends Component {
  _isMounted = false;

  source = null;

  state = {
    encoder: {},
    status: false,
    openTail: false,
    tail: '',
    uptime: '',
    loading: false,
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.propsToState()
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = async (props = this.props) => {
    if (!this._isMounted) return;
    this._isMounted = false;
    const { encoder } = props;
    let cardStaus = false, uptime = '';
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    this.source = source;
    const status = await getEncoderStatus(encoder._id, source.token);
    if (status && status.results && status.status) {
      uptime = status.results.substring(status.results.search(/uptime/g));
      cardStaus = status.results.includes('RUNNING');
    }
    this.setState({ encoder, status: cardStaus, uptime });
  }

  componentWillUnmount() {
    this.source.cancel("axios request cancelled");
    this._isMounted = false;
  }

  removeEncoder = async () => {
    this.props.showAlert('Are you sure?', 'Are you sure you want to delete this Encoder config?', async () => {
      this.props.setLoading(true);
      const encoder = await configDeleteApi(this.state.encoder._id);
      if (encoder._id) this.setState({ encoder })
      this.props.setLoading(false);
    })
  }

  actions = async command => {
    const { encoder } = this.state;
    if (!encoder.config) {
      ToastsStore.info('This encoder is not yet configured.')
      return;
    }
    if (command === 'stop') {
      this.props.showAlert('Are you sure?', 'Are you sure you want to Stop this Encoder?', async () => {
        await this.sendRquest(encoder._id, {
          name: encoder.config.name,
          command
        });
      })
    } else {
      await this.sendRquest(encoder._id, {
        name: encoder.config.name,
        command
      }, command);
    }
    // this.props.onDelete && this.props.onDelete();
  }

  sendRquest = async (id, request, command) => {
    // this.props.setLoading(true);
    this.setState({ loading: true })
    const response = await controlApi(id, request);
    if (response && response.results) {
      if (command === 'tail') {
        this.setState({ openTail: true, tail: response.results })
      } else {
        ToastsStore.success(response.results)
      }
      const cardStaus = await getEncoderStatus(id);
      this.setState({ status: cardStaus.results.includes('RUNNING') });
    } else {
      ToastsStore.info('Don\'t get any response');
    }
    this.setState({ loading: false })
  }
  editConfig = () => {
    const { encoder } = this.state;
    this.props.history.push(`/dashboard/editEncoder/${encoder._id}`)
  }

  render() {
    const { encoder, openTail, tail, status, uptime, loading } = this.state;
    const config = encoder.config || {}

    const makeBackground = icon => ({
      backgroundImage: `url(${require(`../../assets/icons/${icon}.png`)})`
    })
    return (
      <div className={`panda-encoder-card`}>
        {loading ? <div className="loading" /> : null}
        <div className="card-header">{encoder.title}</div>
        <div className="content">
          <div className={`active`} style={makeBackground(status ? 'active' : 'down')}></div>
          <div className="info">
            <div>{config['video-format'] || ''}</div>
            <div>{config['muxer'] ? `${Math.round(config['muxer']['ts-muxrate'] / 1000)}kbps` : ''}</div>
            <div className="upper-case">{`${config['video-stream'] ? 'H.264' : ''}${config['audio-stream'] ? ` + ${config['audio-stream']['format']}` : ''}`}</div>
            <div>{`${config['muxer'] ? config['muxer']['cbr'] : ''}`}</div>
            <div>{uptime}</div>
          </div>
        </div>
        <div className="actions">
          <div>
            <div className="start-btn" onClick={() => this.actions('start')} style={makeBackground('start')} />
            <div className="refresh-btn" onClick={() => this.actions('restart')} style={makeBackground('refresh')} />
            <div className="stop-btn" onClick={() => this.actions('stop')} style={makeBackground('stop')} />
          </div>
          <div>
            <div className="edit-btn" onClick={this.editConfig} style={makeBackground('edit')} />
            <div className="delete-btn" onClick={() => this.removeEncoder(encoder.name)} style={makeBackground('delete')} />
            <div className="info-btn" onClick={() => this.actions('tail')} style={makeBackground('info')} />
          </div>
        </div>
        <TailDialog open={openTail} onClose={() => this.setState({ openTail: false })} tail={tail} />
      </div>
    )
  }
}

EncoderCard.propTypes = {
  encoder: PropTypes.object.isRequired,
  onDelete: PropTypes.func
}

const mapStateToProps = ({ overloading }) => ({
  overloading
});
export default withRouter(connect(mapStateToProps, {
  setLoading, showAlert
})(EncoderCard));