import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import ServerCreatingDialog from '../../components/Servers/addDialog'
import {
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    Button,
    IconButton,
    Icon
} from '@material-ui/core'
import {paramPost, noParamGet, paramPut, noParamDelete} from '../../apis/CommonApi'
import {ToastsStore} from 'react-toasts'
import {showAlert, setLoading} from '../../action'
import './style.scss'

const Servers = (props) => {
    const [open, setOpen] = useState(false)
    const [servers, setServers] = useState([])
    const [groups, setGroups] = useState([])
    const [editServerId, setEditServerId] = useState(undefined);
    const [statusList, setStatusList] = useState([]);
    const [param, setParam] = useState({
        title: "",
        host: "",
        port: "",
        apiKey: "",
        description: "",
        location: "",
        groupIds: [],
        adapterIps: [],
    })

    useEffect(() => {
        props.setLoading(true)
        getServers()
        getGroups()
    }, [])

    const refresh = async () => {
        props.setLoading(true)
        getServers()
        getGroups()
    }

    const getServers = async () => {
        await noParamGet("/api/servers")
            .then(async res => {
                await getStatus(res.data);
                setServers(res.data);
                props.setLoading(false);
            })
            .catch(err => {
                throw err
            })
    }

    const getStatus = async (servers) => {
        console.log("===== res =====", servers)
        const status = [];
        for (const server of servers) {
            await noParamGet(`/api/servers/status/${server.id}`)
                .then(res => {
                    console.log("res ==", res);
                    if (res) {
                        status.push(server._id);
                        setStatusList(status);
                    }
                })
                .catch(err => {
                    throw err;
                })
        }
    }

    const getGroups = async () => {
        await noParamGet("/api/group")
            .then(res => {
                console.log("Getting group result ==", res)
                setGroups(res.data)
                // props.setLoading(false);
            })
            .catch(err => {
                props.setLoading(false);
                throw err
            })
    }

    const handleOpen = () => {
        setParam({
            title: "",
            host: "",
            port: "",
            apiKey: "",
            description: "",
            location: "",
            groupIds: [],
            adapterIps: []
        })
        setOpen(!open)
        setEditServerId(undefined)
    }

    const handleClose = async (val) => {
        if (val) {
            if (val.groupIds.length) {
                val.groupIds = val.groupIds.map((group) => {
                    return group.split("___")[0]
                })
            }
            if (!editServerId) {
                await paramPost("/api/servers", val)
                    .then(async res => {
                        console.log("server added successfully==", res)
                        ToastsStore.success('Server added successfully');
                        getServers()
                        setOpen(!open)
                    })
                    .catch(err => {
                        console.log(err)
                        ToastsStore.error(err.data.error || 'Failed to create server');
                    })
            } else {
                await paramPut(`/api/servers/${editServerId}`, val)
                    .then(async res => {
                        ToastsStore.success('Server updated successfully');
                        await getServers()
                        setOpen(!open)
                    })
                    .catch(err => {
                        ToastsStore.error(err.data.error || 'Failed to update server');
                    })
            }
        } else {
            setOpen(!open)
        }
    }

    const editServer = (server) => {
        setEditServerId(server._id)
        let serverData = server;
        if (serverData.groupInfo.length) {
            const groupIds = server.groupInfo.map(group => {
                return (
                    group._id + "___" + group.title
                )
            });
            serverData.groupIds = groupIds
        }
        setParam(server)
        setOpen(true);
    }

    const handleRemoveServer = async (id) => {
        await noParamDelete(`/api/servers/${id}`)
            .then(async res => {
                ToastsStore.success('Server deleted successfully')
            })
            .catch(err => {
                ToastsStore.error(err.data.error || 'Failed to delete server');
            })
    }

    const removeServer = (groupId) => {
        console.log("group id", groupId);
        props.showAlert('Are you sure?', 'Are you sure you want to delete this Server?', async () => {
            await handleRemoveServer(groupId);
            await getServers();
        });
    }

    const serverList = () => {
        if (servers.length) {
            return (
                servers.map(server => (
                    <TableRow key={server._id}>
                        <TableCell>{server.title}</TableCell>
                        <TableCell>{server.host}</TableCell>
                        <TableCell>{server.port}</TableCell>
                        <TableCell>{server.groupInfo.length ?
                            server.groupInfo.map((elem) => {
                                return elem.title;
                            }).join(", ") : ""}</TableCell>
                        <TableCell className={statusList.includes(server.id) ? 'color-green' : 'color-danger'}>
                            {statusList.includes(server.id) ? 'Online' : 'Offline'}
                        </TableCell>
                        <TableCell>
                            <IconButton onClick={() => editServer(server)} color="secondary">
                                <Icon>edit</Icon>
                            </IconButton>
                            <IconButton onClick={() => removeServer(server._id)} className="color-danger">
                                <Icon>delete</Icon>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))
            )
        }

    }

    return (
        <div className="group">
            <div className="sub-page-header">
                {'Servers'}
                {/*<Button variant="contained" color="primary" onClick={handleOpen} className="add-button">Add New*/}
                {/*    Server</Button>*/}
                <div className="add-button">
                    <Button variant="text" className={""} onClick={() => refresh()}
                    >Refresh</Button> &nbsp;&nbsp;&nbsp;
                    <Button variant="contained" color="primary" onClick={handleOpen}>Add New
                        Server</Button>
                </div>
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Host</TableCell>
                            <TableCell>Port</TableCell>
                            <TableCell>Groups</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {serverList()}
                    </TableBody>
                </Table>
            </TableContainer>
            <ServerCreatingDialog open={open} onClose={handleClose} params={param} groups={groups}/>
        </div>
    )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, {showAlert, setLoading})(Servers);