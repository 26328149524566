import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from "@material-ui/core/DialogContent"
import { DialogTitle, DialogActions } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import {FormHelperText} from "@material-ui/core"
import './style.scss'

export const GroupDialog = (props) => {
    const { onClose, open, params, users } = props

    const Schema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('Description is required'),
        manager: Yup.string()
            .test("validation", "Select a manager", (value) => value !== 1)
            .required('This field is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: params.title,
            description: params.description,
            manager: params.manager,
        },
        validationSchema: Schema,
        onSubmit: (values, {resetForm}) => {
            handleClose(values)
            // resetForm()
        },
    });

    const handleClose = (val = false) => {
        onClose(val)
    };

    const selectOptions = () => {
        return users.map((item, index) => {
            if(item.modeInfo && item.modeInfo.name === "manager") {
                return (
                    <MenuItem value={item.id} key={index}>{item.fullName}</MenuItem>
                )
            }
        })
    }

    const {errors, touched, handleSubmit, getFieldProps, values} = formik;
    
    return (
        <Dialog disableBackdropClick disableEscapeKeyDown={true} onClose={handleClose} open={open}>
            <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <DialogTitle>Manage group</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Title"
                                    type={"text"}
                                    variant="outlined"
                                    {...getFieldProps('title')}
                                    color={"primary"}
                                    error={Boolean(touched.title && errors.title)}
                                    helperText={touched.title && errors.title}
                                />    
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Description"
                                    type={"text"}
                                    variant="outlined"
                                    {...getFieldProps('description')}
                                    color={"primary"}
                                    error={Boolean(touched.description && errors.description)}
                                    helperText={touched.description && errors.description}
                                />    
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    className='width-100'
                                    error={Boolean(touched.manager && errors.manager)}
                                 >
                                    <InputLabel id="label">
                                        Select Group Admin
                                    </InputLabel>
                                    <Select
                                        labelId="label"
                                        label="Select Group Admin"
                                        {...getFieldProps('manager')}
                                    >
                                        <MenuItem value={1} disabled={true}>
                                            Select from list
                                        </MenuItem>
                                        {selectOptions()}
                                    </Select>
                                    <FormHelperText>{touched.manager && errors.manager}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className='mx-1'>
                        <Button type='submit' disabled={!values.title || !values.description || values.manager === "1"} variant="contained" color="primary">
                            {params.title ? 'edit' : 'save'}
                        </Button>
                        <Button type='reset' variant="contained" color="secondary" className='ml-1' onClick={() => {formik.resetForm(); handleClose()}}>
                            close
                        </Button>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    )
}

GroupDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired
}

export default (GroupDialog)
