import React, {Component} from 'react'
import {
    Button,
    DialogActions,
    DialogTitle,
    FormHelperText,
    Grid,
    Icon,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';

import './style.scss'
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import {setLoading, showAlert} from "../../../action";
import {connect} from "react-redux";
import {noParamGet, noParamPost, paramPost} from "../../../apis/CommonApi";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import defaultJson from '../default.json'
import {
    deleteAcanFeederFromEncoder,
    getEncoderAcanFeederStatusByName,
    updateEncoderAcanFeeder
} from "../../../apis/Encoder";
import {ToastsStore} from 'react-toasts';
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import StopIcon from "@material-ui/icons/Stop";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ReplayIcon from "@material-ui/icons/Replay";
import Collapse from "@material-ui/core/Collapse";
import SubTable from "../../../components/Contribution/SubTable";
import EnhancedTableHead from "../../../components/Contribution/EnhancedTableHead";
import {withRouter} from "react-router-dom";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";


/**
 * edit audio setting
 * @augments {Component<Props, State>}
 */
class AcanFeeder extends Component {
    state = {
        // feeders: [],
        feederStatus: [],
        encoder: {},
        encoderFull: {},
        service: {},
        feederTotal: [],
        servers: [],
        groups: [],
        page: 0,
        rowsPerPage: 12,
        searchValue: '',
        modalOpen: false,
        modalEditOpen: false,
        serverSelectedOnChange: "",
        serverSelectedId: "",
        feederPost: {
            name: '',
            input: {
                title: '',
                type: defaultJson.feederInput.type,
                addr: defaultJson.feederInput.addr,
                port: defaultJson.feederInput.port,
                max_bw: '625000',
                multicast: '',
            },
            output: {
                type: defaultJson.feederOutput.type,
                addr: defaultJson.feederOutput.addr,
                port: defaultJson.feederOutput.port,
                latency: defaultJson.feederOutput.latency,
                max_bw: '625000',
                adv_params: defaultJson.feederOutput.adv_params,
            },
        },
        hasError: {
            title: false,
            multicast: false,
            inputPort: false,
            selectServer: false,
            outputTarget: false,
            outputport: false,
            outputlatency: false,
            requireErrorMessage: "This is required!"
        },
        openSub: -1,
        selected: [],
        targetIps: [],
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        prevState.encoder = prevState.encoder ?? [];

        if (typeof prevState.encoder.feeder !== 'undefined') {
            if (nextProps.encoder.feeder.length !== prevState.encoder.feeder.length) {
                nextProps.encoder.feeder = prevState.encoder.feeder;
            }
        }
        if (typeof prevState.encoderFull.feeder !== 'undefined') {
            if (nextProps.encoderFull.config.feeder.length !== prevState.encoderFull.config.feeder.length)
                nextProps.encoderFull.config.feeder = prevState.encoderFull.config.feeder;
        }
        const {encoder, service, encoderFull} = nextProps;
        return {
            encoder: encoder || {},
            encoderFull: encoderFull || {},
            service: service || {},
        }
    }

    initFeederToDefaultJson() {
        let {feederPost} = this.state;
        feederPost = {
            name: '',
            input: {
                title: '',
                type: defaultJson.feederInput.type,
                addr: defaultJson.feederInput.addr,
                port: defaultJson.feederInput.port,
                multicast: '',
            },
            output: {
                type: defaultJson.feederOutput.type,
                addr: defaultJson.feederOutput.addr,
                port: defaultJson.feederOutput.port,
                latency: defaultJson.feederOutput.latency,
                adv_params: defaultJson.feederOutput.adv_params,
            },
        };
        this.setState({feederPost: feederPost});
    }

    componentDidMount() {
        this.getInitialValues();
    }

    getInitialValues = async () => {
        try {
            this.props.setLoading(true);
            const servers = await noParamGet("/api/servers");
            const {encoderFull, feederStatus} = this.state;
            this.setState({servers: servers.data});
            encoderFull.config.feeder.map(async (row, index) => {
                let status = await getEncoderAcanFeederStatusByName(encoderFull.id, row.name);
                feederStatus.push(status);
                this.setState({feederStatus: feederStatus});
            });
            this.props.setLoading(false);
        }
        catch (e) {
            this.props.setLoading(false);
            // this.props.history.push("/dashboard/managerEncoder");
            ToastsStore.error("Please save the encoder config before adding a Feeder");
        }
    }

    actionTailOld = async (id, type, actionType, feederName) => {
        return await paramPost('/api/contribution/actions', {id, type, actionType, feederName})
            .then(async res => {
                console.log(res)
                if (res.data.status) {
                    if (type === 10) {
                        let results = res.data.results.split('\n').filter(el => {
                            return el != null && el !== '';
                        });
                        results = results[results.length -1];
                        return results.toLowerCase().includes('connected') || results.toLowerCase().includes('connection')  ? 'connected' : 'disconnected';
                    }
                } else {
                    return 'disconnected';
                }
            })
            .catch(err => {
                ToastsStore.error(err.data.error || 'Something went wrong');
                this.props.setLoading(false);
                return 'disconnected';
            })
    }

    actionTail = async (id, type, actionType, feederName) => {
        return await paramPost('/api/contribution/actions', {id, type, actionType, feederName})
            .then(async res => {
                console.log(res)
                if (res.data.status) {
                    let results = res.data.results.split('\n').filter(el => {
                        return el != null && el !== '';
                    });
                    results = results[results.length - 1];
                    console.log("ACTION TAIL LAST STRING====", results)
                    if (results.toLowerCase().match(/(^|\W)connected($|\W)/) || results.toLowerCase().match(/(^|\W)accepted($|\W)/)) {
                        return 'connected';
                    } else if (results.toLowerCase().match(/(^|\W)disconnected($|\W)/)) {
                        return "disconnected";
                    } else return "vide";
                } else {
                    return 'vide';
                }
            })
            .catch(err => {
                ToastsStore.error(err.data.error || 'Something went wrong');
                this.props.setLoading(false);
                return 'vide';
            })
    }

    handleCollapse = async (index, row) => {
        let {openSub, encoderFull} = this.state;
        console.log("ROW=",row)
        console.log("ENCODER=",encoderFull)
        if (index === openSub) {
            return this.setState({openSub: -1});
        }
        this.props.setLoading(true);
        const res = await this.actionTail(encoderFull.id, 10, 'tail', row.name);
        console.log(res)
        encoderFull.config.feeder[index].tailInfo = res;
        this.setState({encoderFull: encoderFull});
        this.props.setLoading(false);
        this.setState({openSub: index});
    }

    setOpenModal = (status) => {
        console.log(this.state.encoderFull)
        if(!this.state.encoderFull || !this.state.encoderFull.config) {
            ToastsStore.error("Please save the encoder config before adding a Feeder");
            return false
        }
        this.setState({modalOpen: status});
    }

    setOpenEditModal = (status) => {
        this.setState({modalEditOpen: status});
    }

    handleCloseModal = (val = false) => {
        let feederPost = this.state.feederPost;
        feederPost.input.title = '';
        feederPost.input.type = defaultJson.feederInput.type;
        feederPost.input.addr = defaultJson.feederInput.addr;
        feederPost.input.port = defaultJson.feederInput.port;
        feederPost.input.multicast = '';
        feederPost.output.port = defaultJson.feederOutput.port;
        feederPost.output.addr = defaultJson.feederOutput.addr;
        feederPost.output.port = defaultJson.feederOutput.port;
        feederPost.output.latency = defaultJson.feederOutput.latency;
        this.setState({modalOpen: false, modalEditOpen: false, feederPost: feederPost});
    }

    handleSubmit = async e => {
        e.preventDefault();
        if (this.submitDataIsValid()) {
            const {encoder, service, feederPost, encoderFull, serverSelectedOnChange} = this.state;
            const allFeeder = encoderFull.config.feeder;
            const inputPortExist = allFeeder.filter(feed => feed.input.port === feederPost.input.port);
            if (inputPortExist.length > 0) {
                ToastsStore.error(`Input port ${feederPost.input.port} is already taken`)
                return false;
            }
            this.props.setLoading(true);
            let outputPost = feederPost.output;
            feederPost.output = [];
            feederPost.output.push(outputPost);
            feederPost.name = Math.random().toString(36).slice(2);
            feederPost.input.addr = feederPost.input.addr + ":" + feederPost.input.port;
            feederPost.output[0].addr = feederPost.output[0].addr + ':' + feederPost.output[0].port;
            encoder.feeder.push(feederPost);
            service.config = encoder;
            feederPost.serverId = serverSelectedOnChange;
            service.feederPost = feederPost;
            const rs = await updateEncoderAcanFeeder(service);
            if(rs.status) {
                localStorage.setItem('displayFeeder', 'true');
                window.location.reload();
            }
            else {
                ToastsStore.error("Failure update encoder");
                encoder.feeder.pop();
                service.config = encoder;
                this.setState({targetIps: []});
            }
            this.props.setLoading(false);
            this.initFeederToDefaultJson();
            this.handleCloseModal(false);
        }
    }

    handleEdit = async e => {
        e.preventDefault();
        if (this.submitDataIsValid()) {
            this.props.setLoading(true);
            const {encoder, service, feederPost, serverSelectedOnChange} = this.state;
            const originalEncoderBeforeUpdate = encoder;
            var outputPost = feederPost.output;
            feederPost.output = [];
            feederPost.output.push(outputPost);
            feederPost.input.addr = defaultJson.feederInput.addr + ":" + feederPost.input.port;
            feederPost.output[0].addr = feederPost.output[0].addr + ':' + feederPost.output[0].port;
            const updateIndex = encoder.feeder.findIndex(feed => feed.name === feederPost.name);
            encoder.feeder[updateIndex] = feederPost;
            service.config = encoder;
            feederPost.serverId = serverSelectedOnChange;
            service.feederPost = feederPost;
            const rs = await updateEncoderAcanFeeder(service);
            if(rs.status) {
                ToastsStore.success("Feeder updated Successfully");
            }
            else {
                ToastsStore.error("Failure update encoder");
                service.config = originalEncoderBeforeUpdate;
                this.setState({targetIps: []});
            }
            this.props.setLoading(false);
            this.initFeederToDefaultJson();
            this.handleCloseModal(false);
        }
    }

    submitDataIsValid() {
        const {feederPost, hasError, serverSelectedOnChange} = this.state;
        let error = false;
        if (!feederPost.input.port.length > 0) {
            hasError.inputPort = true;
            error = true;
        } else hasError.inputPort = false;
        if (!serverSelectedOnChange.length > 8) {
            hasError.selectServer = true;
            error = true;
        } else hasError.selectServer = false;
        if (!feederPost.output.addr.length > 0) {
            hasError.outputTarget = true;
            error = true;
        } else hasError.outputTarget = false;
        if (!feederPost.output.port > 0) {
            hasError.outputportu = true;
            error = true;
        } else hasError.outputportu = false;
        if (!feederPost.output.latency.length > 0) {
            hasError.outputlatency = true;
            error = true;
        } else hasError.outputlatency = false;
        this.setState({hasError: hasError});
        return !error;
    }

    selectOptions = (items) => {
        return items.map((item, index) => {
            return (
                <MenuItem value={item.id} key={index}>{item.title}</MenuItem>
            )
        })
    }

    handleSelectTargetIP = e => {
        let feederPost = {...this.state.feederPost};
        feederPost.output.addr = e.target.value;
        this.setState({feederPost: feederPost});
    }

    handleSelectServer = async e => {
        this.props.setLoading(true);
        const {servers, feederPost, encoderFull} = this.state;
        let server = servers.find(item => item.id === e.target.value);
        const serverInfo = await noParamGet(`/api/servers/${server.id}`);
        const ips = serverInfo.data.adapterIps.length ? serverInfo.data.adapterIps : [];
        const portAvailable = await noParamPost("/api/contribution/feeder-next-input-port");
        feederPost.input.multicast = await noParamPost("/api/contribution/multicast");
        feederPost.output.addr = '';
        feederPost.output.port = portAvailable;
        feederPost.input.title = `${encoderFull.config.title || 'Encoder'} ${portAvailable}`;
        this.setState({
            serverSelectedOnChange: e.target.value,
            feederPost: feederPost,
            targetIps: Array.from(ips, ip => ip.adapterIp) || [],
        });
        this.props.setLoading(false);
    }

    /**
     * Par default le port est egale  au port du serveur.
     * -On recupere le dernier feeder et verifier si son host est egal au host du server selected, si c'est le cas
     *      on increment le port du dernier feeder de 2 qui equivaut au next port available.
     * -Sinon on recupere les feeders qui ont le meme host que le host du server selected et on recupere le
     *      dernier element de ses feeders. Si on a au moins un element on prend son port + 2 =>  qui equivaut au next port available.
     *
     * - Si aucune des conditions n'est valable c'est port par default du server qui est return
     */
    guestServerNextPortAvailable = (serverSelected) => {
        const {encoderFull} = this.state;
        let port = parseInt(serverSelected.port);
        if (encoderFull.config && encoderFull.config.feeder && encoderFull.config.feeder.length > 0) {
            const lastFeeder = encoderFull.config.feeder[encoderFull.config.feeder.length - 1];
            if (lastFeeder) {
                const lastFeederOutput = lastFeeder.output[0];
                if (serverSelected.host === lastFeederOutput.addr.split(':')[0]) {
                    port = parseInt(lastFeederOutput.port) + 2;
                } else {
                    const feedersForNewServer = encoderFull.config.feeder.filter(feed => serverSelected.host === feed.output[0].addr.split(':')[0])
                    if (feedersForNewServer) {
                        let lastElement = feedersForNewServer[feedersForNewServer.length - 1];
                        if (lastElement) {
                            port = parseInt(lastElement.port) + 2;
                        }
                    }
                }
            }
        }
        return port;
    }

    changeValue = field => evt => {
        const {feederPost} = this.state
        switch (field) {
            case 'feederPost.input.title':
                feederPost.input.title = evt.target.value;
                this.setState({feederPost: feederPost});
                break;
            case 'feederPost.input.port':
                feederPost.input.port = evt.target.value;
                this.setState({feederPost: feederPost});
                break;
            case 'feederPost.input.multicast':
                feederPost.input.multicast = evt.target.value;
                this.setState({feederPost: feederPost});
                break;
            case 'feederPost.output.port':
                feederPost.output.port = evt.target.value;
                this.setState({feederPost: feederPost});
                break;
            case 'feederPost.output.latency':
                feederPost.output.latency = evt.target.value;
                this.setState({feederPost: feederPost});
                break;
        }

    };

    isSelected = (name) => this.state.selected.indexOf(name) !== -1;

    handleClick = (event, name) => {
        const {selected} = this.state;
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({selected: newSelected});
    };

    handleAction = async (id, type, actionType) => {
        const {encoderFull} = this.state;
        let feederName = id;
        id = encoderFull.id;
        await paramPost('/api/contribution/actions', {id, type, actionType, feederName})
            .then(async res => {
                if (res.data.status) {
                    ToastsStore.success('Action worked success');
                    localStorage.setItem('displayFeeder', 'true');
                    window.location.reload()
                } else {
                    ToastsStore.error(res.data.error || 'Something went wrong');
                }
            })
            .catch(err => {
                ToastsStore.error(err.data.error || 'Something went wrong');
            })
    }

    editFeeder = async (row) => {
        this.setOpenEditModal(true)
        this.props.setLoading(true);
        const {feederPost, servers} = this.state;
        let input = feederPost.input;
        let output = feederPost.output;
        input.title = row.input.title;
        input.addr = row.input.addr;
        input.port = row.input.port;
        input.type = row.input.type;
        input.multicast = row.input.multicast;
        output.addr = row.output[0].addr.split(':')[0];
        output.latency = row.output[0].latency;
        output.port = row.output[0].port;
        output.type = row.output[0].type;
        feederPost.input = input;
        feederPost.output = output;
        feederPost.name = row.name;
        let serverSelected = servers.find(serv => serv.id === row.serverId);
        const serverInfo = await noParamGet(`/api/servers/${serverSelected.id}`);
        const ips = serverInfo.data.adapterIps.length ? serverInfo.data.adapterIps : [];
        this.setState({
            serverSelectedId: serverSelected.id,
            serverSelected,
            serverSelectedOnChange: serverSelected.id,
            targetIps: Array.from(ips, ip => ip.adapterIp) || []
        });
        this.props.setLoading(false);
    }

    removeFeeder = async (encoderId, feederName) => {
        this.props.showAlert('Are you sure?', 'Are you sure you want to delete this Feeder?', async () => {
            await this.deleteFeeder(encoderId, feederName);
        });
    }

    deleteFeeder = async (encoderId, feederName) => {
        try {
            let res = await deleteAcanFeederFromEncoder(encoderId, feederName);
            if (res) {
                ToastsStore.success("Success deleted");
                res.config.feeder = res.config.feeder.filter(feed => feed.name !== feederName);
                this.setState({encoderFull: res, encoder: res.config});
            } else
                ToastsStore.error("An error occurred when deleting feeder");

        } catch (error) {
            ToastsStore.error("error");
        }
    }

    feederIsRunning(feederResult) {
        const {status, results} = feederResult;
        if (status) {
            if (results.includes("STOPPED")) {
                return false;
            }
            return true;
        }
        return false;
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.state.encoder.feeder.map((n) => n.name);
            this.setState({selected: newSelecteds});
            return;
        }
        this.setState({selected: []});
    };

    modalView() {
        const {servers, groups, feederPost, hasError, targetIps} = this.state;
        return (
            <Dialog disableBackdropClick disableEscapeKeyDown={true} onClose={this.handleCloseModal}
                    open={this.state.modalOpen} fullWidth maxWidth="md">
                <form autoComplete="off" onSubmit={this.handleSubmit}>
                    <DialogTitle>FEEDER</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Input port"
                                    type={"number"}
                                    variant="outlined"
                                    color={"primary"}
                                    error={hasError.inputPort}
                                    value={feederPost.input.port}
                                    onChange={this.changeValue('feederPost.input.port')}
                                    InputProps={{
                                        required: true,
                                    }}
                                />
                                {hasError.inputPort && <FormHelperText>{hasError.requireErrorMessage}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    className='width-100'
                                >
                                    <InputLabel id="label">
                                        Select Server
                                    </InputLabel>
                                    <Select
                                        labelId="label"
                                        label="Select Server"
                                        onChange={this.handleSelectServer}
                                        error={hasError.selectServer}
                                    >
                                        <MenuItem value={1} disabled={true}>
                                            === Select ===
                                        </MenuItem>
                                        {servers.length && this.selectOptions(servers)}
                                    </Select>
                                    {hasError.selectServer &&
                                        <FormHelperText>{hasError.requireErrorMessage}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl
                                    variant="outlined"
                                    className='width-100'
                                >
                                    <InputLabel id="label">
                                        Select Target IP
                                    </InputLabel>
                                    <Select
                                        labelId="targetIpId" fullWidth
                                        className=" height-50"
                                        label="Select Target IP"
                                        onChange={this.handleSelectTargetIP}
                                        error={hasError.selectServer}
                                    >
                                        <MenuItem value={1} disabled={true}>
                                            === Select ===
                                        </MenuItem>
                                        {
                                            targetIps.map((ip, index) => {
                                                return <MenuItem value={ip} key={index}>{ip}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    label="Target Port"
                                    type={"number"}
                                    variant="outlined"
                                    error={hasError.outputport}
                                    color={"primary"}
                                    value={feederPost.output.port}
                                    onChange={this.changeValue('feederPost.output.port')}
                                    InputProps={{
                                        required: true,
                                    }}
                                />
                                {hasError.outputport && <FormHelperText>{hasError.requireErrorMessage}</FormHelperText>}
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    label="Latency"
                                    type={"number"}
                                    variant="outlined"
                                    color={"primary"}
                                    error={hasError.outputlatency}
                                    value={feederPost.output.latency}
                                    onChange={this.changeValue('feederPost.output.latency')}
                                    InputProps={{
                                        required: true,
                                    }}
                                />
                                {hasError.outputlatency &&
                                    <FormHelperText>{hasError.requireErrorMessage}</FormHelperText>}
                            </Grid>
                            <Grid item xs={6} style={{marginTop: '10px'}}>
                                <TextField
                                    label="Title"
                                    type={"text"}
                                    variant="outlined"
                                    color={"primary"}
                                    error={hasError.title}
                                    value={feederPost.input.title}
                                    onChange={this.changeValue('feederPost.input.title')}
                                    InputProps={{
                                        required: true,
                                    }}
                                />
                                {hasError.title && <FormHelperText>{hasError.requireErrorMessage}</FormHelperText>}
                            </Grid>
                            <Grid item xs={6} style={{marginTop: '10px'}}>
                                <TextField
                                    label="Multicast"
                                    type={"text"}
                                    variant="outlined"
                                    color={"primary"}
                                    error={hasError.multicast}
                                    value={feederPost.input.multicast}
                                    onChange={this.changeValue('feederPost.input.multicast')}
                                    InputProps={{
                                        required: true,
                                    }}
                                />
                                {hasError.multicast && <FormHelperText>{hasError.requireErrorMessage}</FormHelperText>}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className='mx-1'>
                        <Button type='submit'
                                variant="contained" color="primary">
                            ok
                        </Button>
                        <Button type='reset' variant="contained" color="secondary" className='ml-1' onClick={() => {
                            this.handleCloseModal()
                        }}>
                            close
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }

    modalEditView() {
        const {servers, groups, feederPost, hasError, targetIps} = this.state;
        return (
            <Dialog disableBackdropClick disableEscapeKeyDown={true} onClose={this.handleCloseModal}
                    open={this.state.modalEditOpen} fullWidth maxWidth="md">
                <form autoComplete="off" onSubmit={this.handleEdit}>
                    <DialogTitle>FEEDER</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label="Input port"
                                    type={"number"}
                                    variant="outlined"
                                    color={"primary"}
                                    error={hasError.inputPort}
                                    value={feederPost.input.port}
                                    onChange={this.changeValue('feederPost.input.port')}
                                    InputProps={{
                                        required: true,
                                    }}
                                />
                                {hasError.inputPort && <FormHelperText>{hasError.requireErrorMessage}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    className='width-100'
                                >
                                    <InputLabel id="label">
                                        Select Server
                                    </InputLabel>
                                    <Select
                                        labelId="label"
                                        label="Select Server"
                                        onChange={this.handleSelectServer}
                                        error={hasError.selectServer}
                                        value={this.state.serverSelectedOnChange}
                                    >
                                        <MenuItem value={1} disabled={true}>
                                            === Select ===
                                        </MenuItem>
                                        {servers.length && this.selectOptions(servers)}
                                    </Select>
                                    {hasError.selectServer &&
                                        <FormHelperText>{hasError.requireErrorMessage}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl
                                    variant="outlined"
                                    className='width-100'
                                >
                                    <InputLabel id="label">
                                        Select Target IP
                                    </InputLabel>
                                    <Select
                                        labelId="targetIpId" fullWidth
                                        className=" height-50"
                                        label="Select Target IP"
                                        value={feederPost.output.addr}
                                        onChange={this.handleSelectTargetIP}
                                        error={hasError.selectServer}
                                    >
                                        <MenuItem value={1} disabled={true}>
                                            === Select ===
                                        </MenuItem>
                                        {
                                            targetIps.map((ip, index) => {
                                                return <MenuItem value={ip} key={index}>{ip}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    label="Target Port"
                                    type={"number"}
                                    variant="outlined"
                                    error={hasError.outputport}
                                    color={"primary"}
                                    value={feederPost.output.port}
                                    onChange={this.changeValue('feederPost.output.port')}
                                    InputProps={{
                                        required: true,
                                    }}
                                />
                                {hasError.outputport && <FormHelperText>{hasError.requireErrorMessage}</FormHelperText>}
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    label="Latency"
                                    type={"number"}
                                    variant="outlined"
                                    color={"primary"}
                                    error={hasError.outputlatency}
                                    value={feederPost.output.latency}
                                    onChange={this.changeValue('feederPost.output.latency')}
                                    InputProps={{
                                        required: true,
                                    }}
                                />
                                {hasError.outputlatency &&
                                    <FormHelperText>{hasError.requireErrorMessage}</FormHelperText>}
                            </Grid>
                            <Grid item xs={6} style={{marginTop: '10px'}}>
                                <TextField
                                    label="Title"
                                    type={"text"}
                                    variant="outlined"
                                    color={"primary"}
                                    error={hasError.title}
                                    value={feederPost.input.title}
                                    onChange={this.changeValue('feederPost.input.title')}
                                    InputProps={{
                                        required: true,
                                    }}
                                />
                                {hasError.title && <FormHelperText>{hasError.requireErrorMessage}</FormHelperText>}
                            </Grid>
                            <Grid item xs={6} style={{marginTop: '10px'}}>
                                <TextField
                                    label="Multicast"
                                    type={"text"}
                                    variant="outlined"
                                    color={"primary"}
                                    error={hasError.multicast}
                                    value={feederPost.input.multicast}
                                    onChange={this.changeValue('feederPost.input.multicast')}
                                    InputProps={{
                                        required: true,
                                    }}
                                />
                                {hasError.multicast && <FormHelperText>{hasError.requireErrorMessage}</FormHelperText>}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className='mx-1'>
                        <Button type='submit'
                                variant="contained" color="primary">
                            ok
                        </Button>
                        <Button type='reset' variant="contained" color="secondary" className='ml-1' onClick={() => {
                            this.handleCloseModal()
                        }}>
                            close
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }

    render() {
        const {rowsPerPage, page, feederTotal} = this.state;
        const {encoder, encoderFull, feederStatus, openSub, selected, servers} = this.state;
        const mainHeader = ['Name', 'Input Port', 'Target Port', 'Server', 'Actions']
        return (
            <div className="feeder">
                <Grid item xs={12}>
                    <div>
                        <Grid item xs={12} md={12}
                              style={{margin: '2rem 0 1rem', display: 'flex', justifyContent: 'end'}}>
                            <Button variant="contained" color="primary" onClick={() => this.setOpenModal(true)}
                            >Add New Feeder</Button>
                        </Grid>
                    </div>
                </Grid>
                <Grid>
                    <Paper>
                        <TableContainer>
                            <Table
                                className=''
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    rowCount={encoder && encoder.feeder ? encoder.feeder.length : 0}
                                    headerList={mainHeader}
                                    isSubHeader={false}
                                />
                                <TableBody>
                                    {encoderFull && encoderFull.config && encoderFull.config.feeder.length ? encoderFull.config.feeder.map((row, index) => {
                                        const isItemSelected = this.isSelected(row.name);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        let server = servers.find(serv => serv.id === (row.serverId || ''));
                                        return (
                                            <React.Fragment key={row.id}>
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell>
                                                        <IconButton aria-label="expand row" size="small"
                                                                    onClick={() => this.handleCollapse(index, row)}>
                                                            {openSub === index ? <KeyboardArrowUpIcon/> :
                                                                <KeyboardArrowDownIcon/>}
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell padding="checkbox">
                                                        {/*<Checkbox*/}
                                                        {/*    checked={isItemSelected}*/}
                                                        {/*    onClick={(event) => this.handleClick(event, row.name)}*/}
                                                        {/*    inputProps={{'aria-labelledby': labelId}}*/}
                                                        {/*/>*/}
                                                    </TableCell>
                                                    <TableCell align="left">{row.name}</TableCell>
                                                    <TableCell align="left">{row.input.port}</TableCell>
                                                    <TableCell align="left">{row.output[0].port}</TableCell>
                                                    <TableCell align="left">{server ? server.title : ""}</TableCell>
                                                    <TableCell align="left">
                                                        {feederStatus[index] && this.feederIsRunning(feederStatus[index]) ? (
                                                            <>
                                                                <Tooltip title="Stop" className='color-danger'>
                                                                    <IconButton
                                                                        onClick={() => this.handleAction(row.name, 10, "stop")}>
                                                                        <StopIcon/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Restart">
                                                                    <IconButton color="secondary"
                                                                                onClick={() => this.handleAction(row.name, 10, "restart")}>
                                                                        <ReplayIcon/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Tooltip title="Start">
                                                                    <IconButton color="secondary"
                                                                                onClick={() => this.handleAction(row.name, 10, "start")}>
                                                                        <PlayArrowIcon/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                        <Tooltip title="Edit">
                                                            <IconButton color="primary"
                                                                        onClick={() => this.editFeeder(row)}>
                                                                <Icon>edit</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton
                                                                onClick={() => this.removeFeeder(encoderFull.id, row.name)}
                                                                className='color-danger'>
                                                                <DeleteForeverIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                                                        <Collapse in={openSub === index} timeout="auto" unmountOnExit>
                                                            {row.tailInfo && row.tailInfo.length ? (
                                                                <TableRow style={{height: 53}}>
                                                                    <TableCell colSpan={8}>
                                                                        <Typography
                                                                            className={
                                                                                row.tailInfo && row.tailInfo === 'connected' ? 'color-green' :
                                                                                    row.tailInfo && row.tailInfo === 'disconnected' ? 'color-danger' :
                                                                                        ''
                                                                            }>
                                                                            {
                                                                                (row.tailInfo && row.tailInfo) === 'connected' ?
                                                                                    "TARGET STATUS: CONNECTED" :
                                                                                    (row.tailInfo && row.tailInfo) === 'disconnected' ?
                                                                                        "TARGET STATUS: DISCONNECTED" :
                                                                                        "TARGET STATUS:"
                                                                            }
                                                                        </Typography>
                                                                        {/*<Typography*/}
                                                                        {/*    className={row.tailInfo && row.tailInfo === 'connected' ? 'color-green' : 'color-danger'}>*/}
                                                                        {/*    {row.tailInfo ? row.tailInfo === 'connected' ? "TARGET STATUS: CONNECTED" : "TARGET STATUS: DISCONNECTED" : "DISCONNECTED"}*/}
                                                                        {/*</Typography>*/}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ) : (
                                                                <TableRow style={{height: 53}}>
                                                                    <TableCell colSpan={8}/>
                                                                </TableRow>
                                                            )}
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        )
                                    }) : ('')}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={12}
                            component="div"
                            count={feederTotal}
                            rowsPerPage={rowsPerPage}
                            page={page}
                        />
                    </Paper>
                </Grid>

                {this.modalView()}
                {this.modalEditView()}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {showAlert, setLoading};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AcanFeeder));