import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { setLoading, editEncoder } from '../../action'
import { getEncoders } from '../../apis/Encoder';
import { EncoderCard } from '../../components';
import {TablePagination, Grid, Button, Box, Select, MenuItem} from '@material-ui/core';
import SearchBar from '../../components/Common/searchbar';
import { paramPost } from '../../apis/CommonApi';
import './style.scss'
class EncoderList extends Component {
  _isMounted = false;

  state = {
    encoders: [],
    rowsPerPage: 28,
    page: 0,
    searchValue: '',
    total: 0
  }


  async componentDidMount() {
    this._isMounted = true;
    await this.propsToState()
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = async (props = this.props) => {
    if (!this._isMounted) return;
    this._isMounted = false;
    this.props.setLoading(true);
    const {rowsPerPage, page, searchValue} = this.state;
    await this.getEncoderLists({rowsPerPage, page, searchValue});    
    this.props.setLoading(false);
  }

  getEncoderLists = async (params) => {    
    const response = await paramPost("/api/encoder/all", params);
    console.log("res ====", response.data)
    if (response.data)
      this.setState({ 
        encoders: response.data.result || [],
        total: response.data.total 
    });
  }

  onUpdate = async () => {
    this._isMounted = true;
    await this.propsToState();
  }

  handleChangePage = async (event, newPage) => {
    console.log("Page change", newPage);
    this.setState({page: newPage});
    const {rowsPerPage, searchValue} = this.state;
    await this.getEncoderLists({rowsPerPage, page: newPage, searchValue});
  };

  handleRowsPerPageChange = async (event) => {
    this.props.setLoading(true);
    const newRowsPerPage = event.target.value;
    console.log("Rows per opage change", newRowsPerPage);
    this.setState({rowsPerPage: newRowsPerPage});
    const {rowsPerPage, searchValue} = this.state;
    await this.getEncoderLists({rowsPerPage: newRowsPerPage, page: 0, searchValue});
    this.props.setLoading(false);
  };

  handleSearch = async (value) => {
    console.log("Search value", value)
    this.setState({searchValue: value});
    const {rowsPerPage, page} = this.state;
    // await this.getEncoderLists({ rowsPerPage, page, searchValue: value });
    if(value.length > 0) {
      await this.getEncoderLists({ rowsPerPage: 0, page: 0, searchValue: value });
    }
    else {
      await this.getEncoderLists({ rowsPerPage, page, searchValue: value });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { encoders, rowsPerPage, page, total } = this.state;
    return (
      <>
      <Grid container className='search-header'>
        <Grid item xs={12} md={3} className='search-bar'>
          <SearchBar onChange={this.handleSearch} />
        </Grid>
        <Grid item xs={12} md={9} className='create-btn'>
          <Button variant="text" className={""} onClick={() => this.componentDidMount()}>Refresh</Button> &nbsp;&nbsp;&nbsp;
          <Button variant="contained" color="primary" className={""} onClick={() => this.props.editEncoder()}>Add New Encoder</Button>
        </Grid>
      </Grid>
        <div className="encoder-list">
          {
            encoders.length ? encoders.map(encoder => (
              <EncoderCard encoder={encoder} key={`${encoder._id}-${!!encoder.config}`} onDelete={this.onUpdate} />
            )) : ""
          }          
        </div>
        <TablePagination
          align='right'
          rowsPerPageOptions={[12, 28, 56, 84, 112]}
          component="div"
          count={total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={this.handleChangePage}
          onRowsPerPageChange={(e) => this.handleRowsPerPageChange(e)}
        />
      </>
    )
  }

}

EncoderList.propTypes = {

}
const mapStateToProps = ({ encoderReducer }) => ({
  encoderReducer
});
export default withRouter(connect(mapStateToProps, {
  setLoading, editEncoder
})(EncoderList));