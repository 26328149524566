import React, { Component } from 'react'
import {
  getEncoders,
  deleteEncoder,
  updateEncoder,
  getServiceStatus,
  getEncodersWithPagination
} from '../../apis/Encoder';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { editEncoder, setEncoder, showAlert, setLoading } from '../../action'

import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  IconButton,
  Icon,
  Tooltip,
  Button, Grid
} from '@material-ui/core'

import { AddUserDialog } from '../../components';
import './style.scss'
import {ToastsStore} from "react-toasts";
import TablePagination from "@material-ui/core/TablePagination";
import SearchBar from "../../components/Common/searchbar";
class ManagerEncoder extends Component {
  _isMounted = false;

  state = {
    encoders: [],
    status: [],
    addUserDialog: false,
    selectedUser: [],
    selectedIndex: -1,

    page: 0,
    rowsPerPage: 12,
    searchValue: '',
    encodersPaginate: {
      encoders: [],
      total: 0,
    },
  }

  async componentDidMount() {
    this.props.setLoading(true);
    this._isMounted = true;
    await this.propsToState()
    this.props.setLoading(false);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = async (props = this.props) => {
    if (!this._isMounted) return;
    // const encoders = await getEncoders();
    const {page, rowsPerPage, searchValue} = this.state;
    const encoders = await getEncodersWithPagination({page, rowsPerPage, searchValue});
    this.setState({ encoders: encoders.encoders, encodersPaginate: encoders });
    this.getStatus();
    // this.props.setLoading(false);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  removeEncoder = async id => {
    console.log(id)
    console.log(this.state)
    const encoder = this.state.encoders.find(encoder => encoder.id === id)
    if(encoder.config && encoder.config.feeder && encoder.config.feeder.length > 0) {
      ToastsStore.error("Please remove first feeder.");
      return false;
    }
    this.props.showAlert('Are you sure?', 'Are you sure you want to delete this Encoder?', async () => {
      await deleteEncoder(id);
      this.propsToState();
    });
  }

  openAddUserDialog = index => {
    this.setState({
      addUserDialog: true,
      selectedIndex: index,
      selectedUser: this.state.encoders[index].users
    })
  }

  addUser = async selectedUser => {
    const { selectedIndex, encoders } = this.state;
    if (selectedIndex !== -1) {
      const encoder = encoders[selectedIndex];
      encoder.users = selectedUser;
      await updateEncoder(encoder);
      this.setState({ addUserDialog: false })
    }

  }

  addConfig = encoder => {
    this.props.history.push(`/dashboard/editEncoder/${encoder._id}`);
  }

  getStatus = async () => {
    const status = [];
    for (const encoder of this.state.encoders) {
      await getServiceStatus(encoder._id).then(result => {
        if (result) {
          status.push(encoder._id)
          this.setState({ status })
        }
      })
    }
  }

  handleOutputChangePage = async (event, newPage) => {
    this.props.setLoading(true);
    const {page, rowsPerPage, searchValue} = this.state
    this.setState({page: newPage});
    const encoders = await getEncodersWithPagination({page: newPage, rowsPerPage, searchValue});
    console.log(encoders)
    this.setState({encoders: encoders.encoders, encodersPaginate: encoders});
    this.props.setLoading(false);
    this.getStatus();
  }

  handleSearch = async (value) => {
    console.log("Search value", value)
    this.setState({searchValue: value});
    const {rowsPerPage, page} = this.state;
    if(value.length > 0) {
      const encoders = await getEncodersWithPagination({page: 0, rowsPerPage: 0, searchValue: value});
      this.setState({ encoders: encoders.encoders, encodersPaginate: encoders });

    }
    else {
      const encoders = await getEncodersWithPagination({page, rowsPerPage, searchValue: value});
      this.setState({ encoders: encoders.encoders, encodersPaginate: encoders });
    }
    this.getStatus();
  }

  render() {
    const { encoders, addUserDialog, selectedUser, status, page, rowsPerPage, encodersPaginate } = this.state;
    return (
      <div className="service-manager">
        <div className="sub-page-header">
          {'Encoders Management'}
          {/*<div className="add-button">*/}
          {/*  <Button variant="text" className={""} onClick={() => this.componentDidMount()}>Refresh</Button> &nbsp;&nbsp;&nbsp;*/}
          {/*  <Button variant="contained" color="primary" onClick={() => this.props.editEncoder()}>Add New Encoder</Button>*/}
          {/*</div>*/}
        </div>
        <Grid container className='search-header'>
          <Grid item xs={12} md={3} className='search-bar'>
            <SearchBar onChange={this.handleSearch} />
          </Grid>
          <Grid item xs={12} md={9} className='create-btn'>
            <div className="add-button">
              <Button variant="text" className={""} onClick={() => this.componentDidMount()}>Refresh</Button> &nbsp;&nbsp;&nbsp;
              <Button variant="contained" color="primary" onClick={() => this.props.editEncoder()}>Add New Encoder</Button>
            </div>
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>IP</TableCell>
                <TableCell>port</TableCell>
                <TableCell>email</TableCell>
                <TableCell>Group</TableCell>
                <TableCell>status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                encoders.length && encoders.map((encoder, index) => (
                  <TableRow key={encoder._id}>
                    <TableCell>{encoder.name}</TableCell>
                    <TableCell>{encoder.title}</TableCell>
                    <TableCell>{encoder.ip}</TableCell>
                    <TableCell>{encoder.port}</TableCell>
                    <TableCell>{encoder.email}</TableCell>
                    <TableCell>{encoder.groupInfo ? encoder.groupInfo.title : ''}</TableCell>
                    <TableCell className={status.includes(encoder._id) ? 'on-line' : 'off-line'}>
                      {encoder.config ? status.includes(encoder._id) ? 'Online' : 'Offline' : 'Not Configured'}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Edit Encoder">
                        <IconButton onClick={() => this.props.editEncoder(encoder._id, encoder)} color="secondary">
                          <Icon>edit</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Config">
                        <IconButton onClick={() => this.addConfig(encoder)} className="color-danger">
                          <Icon>add</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Add Users">
                        <IconButton onClick={() => this.openAddUserDialog(index)} className="color-danger">
                          <Icon>group_add</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Remove Users">
                        <IconButton onClick={() => this.removeEncoder(encoder._id)} className="color-danger">
                          <Icon>delete</Icon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[12]}
            component="div"
            count={encodersPaginate.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleOutputChangePage}
        />
        <AddUserDialog
          open={addUserDialog}
          selectedUser={selectedUser}
          onSelect={this.addUser}
          onClose={() => this.setState({ addUserDialog: false })}
        />
      </div>
    )
  }

}

ManagerEncoder.propTypes = {

}

const mapStateToProps = ({ encoderReducer }) => ({
  encoderReducer,
});
export default withRouter(connect(mapStateToProps, {
  editEncoder, setEncoder, showAlert, setLoading
})(ManagerEncoder));