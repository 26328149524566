import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { getEncoder, addEncoder, updateEncoder } from '../../apis/Encoder';
import { Dialog, DialogActions, DialogContent, Button, Grid, TextField, DialogTitle, MenuItem } from '@material-ui/core';
import { isEmpty } from '../../utils/Validation'
import { closeEncoder, setLoading } from '../../action'
import { ToastsStore } from 'react-toasts';
import config from '../../config';
import {noParamGet} from '../../apis/CommonApi';
class EncoderDialog extends Component {
  _isMounted = false;

  state = {
    open: false,
    sended: false,
    encoder: {
      name: 'Encoder',
      apiKey: '20i4Hfre783gdjekqGU7h',
      port: '8000',
      groupId: ''
    },
    groups: []
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.propsToState();
    const groups = await noParamGet("/api/group");
    this.setState({ groups: groups.data });
    if(groups.length && this.loginUser() === "manager") {
      let encoder = this.state.encoder;
      encoder.groupId = groups[0]._id;
      this.setState({encoder});
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = async (props = this.props) => {
    // if (!this._isMounted) return;
    const { open, encoderId, encoderToEdit } = props.encoderReducer;
    try {
      if (open) {
        let encoder = {};
        console.log(encoderToEdit)
        if (encoderToEdit && Object.keys(encoderToEdit).length) {
          encoder = encoderToEdit
        }
        else {
          encoder = encoderId ? await getEncoder(encoderId) || this.state.encoder : this.state.encoder;
          console.log(encoder)
          console.log(encoderId)
          console.log(this.state.encoder)
        }
        // const encoder = encoderId ? await getEncoder(encoderId) || this.state.encoder : this.state.encoder;
        this.setState({ open, encoder });
      } else {
        this.setState({ open })
      }
    } catch (error) {
      console.log(error);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeEncoder = field => evt => {
    const disableFields = ['name'];
    if (disableFields.includes(field)) return;
    const { encoder } = this.state;
    encoder[field] = evt.target.value;
    this.setState({ encoder });
  }

  loginUser = () => {
    let user = localStorage.getItem(config.userLocalstorage)
    if(user) {
      user = JSON.parse(user)
      return user.mode.name
    }
    return null
  }

  onSave = async () => {
    this.setState({
      sended: true
    });
    const { encoder } = this.state;
    if(!this.state.encoder._id && this.loginUser() === 'manager' && this.state.groups.length) {
      encoder.groupId = this.state.groups[0]._id;
    }
    console.log(encoder);
    if (isEmpty(encoder.title, encoder.apiKey, encoder.ip, encoder.port, encoder.groupId)) {
      ToastsStore.error('Please check required fields');
      return;
    }
    this.setState({ open: false });
    let res = null;
    this.props.setLoading(true);
    if (this.state.encoder._id) {
      res = await updateEncoder(this.state.encoder);
    } else {
      res = await addEncoder(this.state.encoder);
      this.props.closeEncoder();
    }
    this.props.setLoading(false);
    if(res) {
      setTimeout(() => {
        window.location.reload();
      }, 300)
    }
  }


  render() {
    const { open, encoder, sended, groups } = this.state;

    const renderEditField = (field, label = field, type = "text", require = false) => (
      <React.Fragment>
        <p className="capitalize m-0 p-0">{`${label}${require ? '*' : ''}`}</p>
        <TextField
          type={type}
          value={encoder[field] || ''}
          onChange={this.changeEncoder(field)}
          error={require && sended && isEmpty(encoder[field])}
        />
      </React.Fragment>
    )
    return (
      <Dialog open={open || false}>
        <DialogTitle>{encoder._id ? 'Edit Encoder' : 'Add Encoder'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <p className="capitalize m-0 p-0">Group*</p>
              <TextField
                select
                label="Select Group"
                // value={encoder.groupId || groups.length && this.loginUser() === "manager" ? groups[0]._id : ''}
                value={encoder.groupId}
                onChange={this.changeEncoder('groupId')}
                error={true && sended && isEmpty(encoder['groupId'])}
              >
                {groups.length && groups.map(group => (
                  <MenuItem value={group._id} key={group._id}>{group.title}</MenuItem>
                ))}

              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>{renderEditField('name', 'Name', 'text', true)}</Grid>
            <Grid item xs={12} md={6}>{renderEditField('title', 'Service Name', 'text', true)}</Grid>
            <Grid item xs={12} md={12}>{renderEditField('description')}</Grid>
            <Grid item xs={12} md={12} className={`${['superadmin', 'admin'].includes(this.loginUser()) ? "" : "display-none"}` }>{renderEditField('apiKey', 'API key', 'text', true)}</Grid>
            <Grid item xs={12} md={6}>{renderEditField('ip', 'IP', 'text', true)}</Grid>
            <Grid item xs={12} md={6}>{renderEditField('port', 'port', 'number', true)}</Grid>
            <Grid item xs={12} md={6}>{renderEditField('location')}</Grid>
            <Grid item xs={12} md={6}>{renderEditField('physicalAddress', 'Physical Address')}</Grid>
            <Grid item xs={12} md={6}>{renderEditField('contactNumber', 'Contact persion number')}</Grid>
            <Grid item xs={12} md={6}>{renderEditField('email')}</Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onSave}>Save</Button>
          <Button color="secondary" onClick={() => this.props.closeEncoder()}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

EncoderDialog.propTypes = {
}
const mapStateToProps = ({ encoderReducer }) => ({
  encoderReducer
});
export default withRouter(connect(mapStateToProps, {
  closeEncoder, setLoading
})(EncoderDialog));