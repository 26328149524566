import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {
  FormControl, 
  FormLabel, 
  RadioGroup, 
  FormControlLabel, 
  Radio, 
  MenuItem, 
  TextField, 
  Button
} from '@material-ui/core'

function Encryption(props) {
  const {values, onConfigChange} = props
  const [params, setParams] = useState({})
  
  useEffect(() => {
    setParams(values)
  }, [])

  const changeValue = (e, field) => {
    const target = e.target.value
    let param = params;
    if(field === 'selection' && target === 'no') {
      param.type = '128';
      param.passphrase = ''
    }
    param[field] = target;
    setParams({...params}, onConfigChange(param));
  }

  const createRandom = () => {
    const randomVal = Math.random().toString(36).slice(2);
    setParams({...params, passphrase: randomVal}, onConfigChange({...params, passphrase: randomVal}))
    console.log("Random creating")
  }

  const keys = [
    { label: 'Passphrase', field: 'passphrase' },
    { label: 'Type', field: 'type', type: 'select', children: ['128', '256'] }
  ]

  const renderItem = ({ label, field, type = 'text', children = [] }) => (
    <div className="lists" key={field}>
      <div className="title">{label}</div>
      <div className="content">
        {
          type === 'select'
            ? <TextField
              fullWidth select
              value={params[field] || ''}
              onChange={(e) => changeValue(e, field)} >
              {
                children.map((child, index) => <MenuItem value={child.value || child} key={child.value || child}>{child.label || child}</MenuItem>)
              }
            </TextField>
            : <TextField
              fullWidth
              type={type}
              value={params[field] || ''}
              onChange={(e) => changeValue(e, field)} />
        }
        {
            field === 'passphrase' ?
              <Button onClick={() => createRandom()}>Generate A Key</Button>
              : null
          }
      </div>
    </div>
  );

  return (
    <div className="panda-audio-stream" style={{width: "100%"}}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Encryption</FormLabel>
        <RadioGroup row name="travel" onChange={(e) => changeValue(e, 'selection')} value={params.selection || ''}>
          <FormControlLabel
            label="Yes"
            value="yes"
            control={<Radio color="primary" />}
          />
          <FormControlLabel
            label="No"
            value="no"
            control={<Radio color="primary" />}
          />
        </RadioGroup>
      </FormControl>
      {params.selection === "yes" && (
        <div className="container">
          {
            keys.map(element => renderItem({ ...element }))
          }
        </div>
      )}
    </div>
  )
}

Encryption.propTypes = {
  values: PropTypes.object.isRequired,
  onConfigChange: PropTypes.func.isRequired
}

export default Encryption