import axios from 'axios';
import AuthHeader from './AuthHeader';
import { ToastsStore } from 'react-toasts'
import config from '../config';
import {paramGet} from "./CommonApi";
const encoderApi = `${config.apiUrl}/encoder`;
export const addEncoder = async (payload) => {
  try {
    const response = await axios.post(`${encoderApi}/add`, payload, AuthHeader());
    ToastsStore.success("Success add new encoder")
    return response.data;
  } catch (error) {
    showError(error);
    ToastsStore.error(error.response.data.error || "Something went wrong, Please try again")
  }
}

export const getEncoder = async id => {
  try {
    const response = await axios.get(`${encoderApi}/${id}`, AuthHeader());
    return response.data;
  } catch (error) {
    showError(error);
  }
}
export const getServiceStatus = async id => {
  try {
    const response = await axios.get(`${encoderApi}/getServiceStatus/${id}`);
    return response.data;
  } catch (error) {
    showError(error);
  }
}

export const getEncoders = async () => {
  try {
    const response = await axios.get(`${encoderApi}`, AuthHeader());
    return response.data;
  } catch (error) {
    showError(error);
  }
}

export const getEncodersWithPagination = async (params) => {
  try {
    return await paramGet(`${encoderApi}`, params);
  } catch (error) {
    showError(error);
  }
}

export const updateEncoder = async (payload) => {
  try {
    const response = await axios.put(`${encoderApi}/update`, payload, AuthHeader());
    ToastsStore.success("Success updated")
    return response.data;
  } catch (error) {
    showError(error);
  }
}

export const updateEncoderAcanFeeder = async (payload) => {
  try {
    const response = await axios.put(`${encoderApi}/update-acan-feeder`, payload, AuthHeader());
    // ToastsStore.success("Success updated")
    return response.data;
  } catch (error) {
    showError(error);
  }
}

export const getEncoderAcanFeederStatus = async (encoderId) => {
  try {
    const response = await axios.get(`${encoderApi}/acanfeeder/status/${encoderId}`, AuthHeader());
    // ToastsStore.success("Success updated")
    return response.data;
  } catch (error) {
    showError(error);
  }
}

export const getEncoderAcanFeederStatusByName = async (encoderId, feederName) => {
  try {
    const response = await axios.get(`${encoderApi}/acanfeeder/status/${encoderId}/${feederName}`, AuthHeader());
    return response.data;
  } catch (error) {
    showError(error);
  }
}

export const deleteAcanFeederFromEncoder = async (encoderId, feederName) => {
  try {
    const response = await axios.delete(`${encoderApi}/acanfeeder/delete/${encoderId}/${feederName}`, AuthHeader());
    return response.data;
  } catch (error) {
    showError(error);
  }
}

export const bulkChangeServer = async (payload) => {
  try {
    const response = await axios.post(`${encoderApi}/bulk/change-server`, payload, AuthHeader());
    return response.data;
  } catch (error) {
    showError(error);
  }
}

export const deleteEncoder = async id => {
  try {
    const response = await axios.delete(`${encoderApi}/${id}`, AuthHeader());
    ToastsStore.success("Success deleted")
    return response.data;
  } catch (error) {
    showError(error);
  }
}

export const getStreamList = async (id = 'all') => {
  try {
    const response = await axios.get(`${encoderApi}/getStreamList/${id}`);
    if (response.data.error) {
      ToastsStore.error(response.data.error);
      return [];
    } else {
      return response.data;
    }
  } catch (error) {
    showError(error);
  }
}

export const controlApi = async (id, data) => {
  try {
    const response = await axios.post(`${encoderApi}/controlApi/${id}`, { data }, AuthHeader());
    if (response.data.error) {
      ToastsStore.error(response.data.error);
      return null;
    } else {
      return response.data;
    }
  } catch (error) {
    showError(error);
  }
}

export const configDeleteApi = async id => {
  try {
    const response = await axios.delete(`${encoderApi}/configDeleteApi/${id}`, AuthHeader());
    if (response.data.error) {
      ToastsStore.error(response.data.error);
      return null;
    } else {
      return response.data;
    }
  } catch (error) {
    showError(error);
  }
}

export const getEncoderStatus = async (id, cancelToken) => {
  try {
    const response = await axios.get(`${encoderApi}/status/${id}`, {cancelToken});
    if (response.data.error) {
      ToastsStore.error(response.data.error);
      return null;
    } else {
      return response.data;
    }
  } catch (error) {
    showError(error);
  }
}

const showError = error => {
  console.log(error);
  // error.response && error.response.data && ToastsStore.error(error.response.data.error || 'Failure');
}
