import config from '../config';
export const checkNotEmptyValues = (...params) => {
  return params.every(param => !!param)
}

export const loginUser = () => {
  let user = localStorage.getItem(config.userLocalstorage)
  if(user) {
    user = JSON.parse(user)
    return user.mode.name
  }
  return null
}